import React from "react";
import { useTranslation } from "next-i18next";
import { MediaButton } from "../../../../../MediaButton";
import { LinkToPlaylist, type LinkToPlaylistProps } from "./LinkToPlaylist";

import styles from "../ListComponentHorizontal.module.scss";

export interface TSeeAllButton extends LinkToPlaylistProps {
  mode?: "link" | "button";
  linkLabel?: string;
}

export const SeeAllButton = ({
  mode = "link",
  linkLabel = "web_browse_content_see_all",
  ...props
}: TSeeAllButton) => {
  const { t } = useTranslation("translations");

  return (
    <LinkToPlaylist {...props}>
      {mode === "link" ? (
        <a className={styles.seeAll}>{t(linkLabel)}</a>
      ) : (
        <MediaButton variant="plain" className={styles.plainSeeAll}>
          {t(linkLabel, "")}
        </MediaButton>
      )}
    </LinkToPlaylist>
  );
};

export default SeeAllButton;
